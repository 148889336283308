import { useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { META } from '../reactorObject.js';
import { useCallbackRef } from '@playful/utils';
import { useImmutableDependencies } from './useImmutableDependencies.js';
function getComponentState(component) {
    return Object.keys(component?.[META]?.properties || {}).reduce((acc, property) => {
        // copy the props.
        if (property in component)
            acc[property] = component[property];
        return acc;
    }, {});
}
/**
 * Returns a component state immediately after its `update` cycle runs. The dependency array allows specifing an array of
 * property keys to subscribe to. Only properties defined in the META are watched. Returns the most updated component state.
 *
 * If you need to, you can use `shouldFlushSync`, but [be aware](https://react.dev/reference/react-dom/flushSync) of its pitfalls.
 *
 * Examples:
 * ```tsx
 * // Logs the component, state, and any changed properties
 * useOnComponentUpdate(component, console.log);
 * useOnComponentUpdate(component, console.log, []);
 *
 * // Logs the component, state, and the changed property (`name`) when the `name` property changes
 * useOnComponentUpdate(component, console.log, ['name']);
 * ```
 */
export function useOnComponentUpdate({ component, shouldFlushSync }, dependencies) {
    const [componentState, setComponentState] = useState(getComponentState(component));
    const hasComponentState = !!componentState;
    // update the state if the component reference changes and the listener doesn't run (or doesn't run in time)
    useEffect(() => {
        hasComponentState && setComponentState(getComponentState(component));
    }, [component, hasComponentState]);
    useComponentSubscription({
        component,
        onStateUpdate: ({ state }) => shouldFlushSync ? flushSync(() => setComponentState(state)) : setComponentState(state),
    }, dependencies);
    return componentState;
}
/**
 * Subscribe to a component's state changes. The dependency array allows specifing an array of property keys to subscribe to.
 * Only properties defined in the META are watched.
 *
 * This differs from `useOnComponentUpdate` in that it does not return the component state from the hook, and thus does not
 * trigger a re-render.
 */
export function useComponentSubscription({ component, onStateUpdate, }, dependencies) {
    const immutableDependencies = useImmutableDependencies(dependencies);
    const cachedCb = useCallbackRef(onStateUpdate);
    useEffect(() => {
        // Shouldn't be required, but is a nice sanity check
        if (!component)
            return;
        // TODO: RadixButton needs to implement onUpdate because it has a fontFamily property and useFontList calls useComponentSubscription.
        const listener = component.onUpdate?.((c, changed) => {
            // If none of the changes properties are in the dependency array, don't update
            if (immutableDependencies.length &&
                !Object.keys(changed).some((p) => immutableDependencies.includes(p)))
                return;
            const updatedState = getComponentState(c);
            cachedCb({ component: c, changed, state: updatedState });
        });
        return () => {
            listener?.dispose();
        };
    }, [component, immutableDependencies, cachedCb]);
}
