/**
 * @internal
 */
export var ContentChangedType;
(function (ContentChangedType) {
    ContentChangedType["Flush"] = "flush";
    ContentChangedType["PropertyChanged"] = "property-changed";
    ContentChangedType["ReactorDeleted"] = "reactor-deleted";
    ContentChangedType["ReactorInserted"] = "reactor-inserted";
})(ContentChangedType || (ContentChangedType = {}));
/**
 * An event describing that a model has been reset to a new value.
 * @internal
 */
export class ModelFlush {
    changeType = ContentChangedType.Flush;
}
/**
 * An event describing that a property has changed in a model.
 * @internal
 */
export class ModelPropertyChanged {
    changeType = ContentChangedType.PropertyChanged;
    /**
     * The id of the Reactor that has changed.
     */
    reactorId;
    /**
     * The name of the property that has changed.
     */
    property;
    /**
     * The new value of the property.
     */
    value;
    /**
     * The old value of the property.
     */
    oldValue;
    constructor(reactorId, property, value, oldValue) {
        this.reactorId = reactorId;
        this.property = property;
        this.value = value;
        this.oldValue = oldValue;
    }
}
/**
 * An event describing that a Reactor has been deleted in a model.
 * @internal
 */
export class ModelReactorDeleted {
    changeType = ContentChangedType.ReactorDeleted;
    /**
     * The ReactorId of the deleted Reactor.
     */
    reactorId;
    /**
     * The ReactorId of the deleted Reactor's parent.
     */
    parentId;
    /**
     * The deleted Reactor.
     */
    reactor;
    constructor(reactorId, parentId, reactor) {
        this.reactorId = reactorId;
        this.parentId = parentId;
        this.reactor = reactor;
    }
}
/**
 * An event describing that a Reactor has been inserted in a model.
 * @internal
 */
export class ModelReactorInserted {
    changeType = ContentChangedType.ReactorInserted;
    /**
     * The ReactorId of the inserted Reactor.
     */
    // TODO: maybe send reactor reference instead?
    reactorId;
    /**
     * The ReactorId of the inserted Reactor's parent.
     */
    parentId;
    constructor(reactorId, parentId) {
        this.reactorId = reactorId;
        this.parentId = parentId;
    }
}
/**
 * @internal
 */
export class ModelContentChangeEvent {
    contentChangedEvent;
    constructor(contentChangedEvent) {
        this.contentChangedEvent = contentChangedEvent;
    }
    merge(other) {
        const contentChangedEvent = ModelContentChangeEvent._mergeChangeEvents(this.contentChangedEvent, other.contentChangedEvent);
        return new ModelContentChangeEvent(contentChangedEvent);
    }
    static _mergeChangeEvents(a, b) {
        const changes = [].concat(a.changes).concat(b.changes);
        const versionId = b.versionId;
        const isUndoing = a.isUndoing || b.isUndoing;
        const isRedoing = a.isRedoing || b.isRedoing;
        const isFlush = a.isFlush || b.isFlush;
        // if both are intermediate, the result is intermediate
        // if one isn't intermediate, the result is not intermediate
        const isIntermediate = a.isIntermediate && b.isIntermediate;
        return {
            changes,
            versionId,
            isUndoing,
            isRedoing,
            isFlush,
            isIntermediate,
        };
    }
}
/**
 * @internal
 */
export class ModelUndoChangeEvent {
    canUndo;
    canRedo;
    constructor(canUndo, canRedo) {
        this.canUndo = canUndo;
        this.canRedo = canRedo;
    }
}
/**
 * @internal
 */
export class ModelSelectionChangeEvent {
    selection;
    constructor(selection) {
        this.selection = selection;
    }
    merge(other) {
        return other;
    }
}
export const SELECTION_UI_STATE = {
    LOCKED: 'locked',
    DELETED: 'deleted',
    IDLE: 'idle',
};
export class ModelSelectionStateChangeEvent {
    state;
    constructor(state) {
        this.state = state;
    }
}
/**
 * @internal
 */
export class ModelDesignRootChangeEvent {
    designRoot;
    oldDesignRoot;
    constructor(designRoot, oldDesignRoot) {
        this.designRoot = designRoot;
        this.oldDesignRoot = oldDesignRoot;
    }
}
/**
 * @internal
 */
export class ModelRootViewChangeEvent {
    rootViewId;
    constructor(rootViewId) {
        this.rootViewId = rootViewId;
    }
}
/**
 * @internal
 */
export class ModelComponentContainerChangeEvent {
    newContainer;
    oldContainer;
    component;
    constructor(newContainer, oldContainer, component) {
        this.newContainer = newContainer;
        this.oldContainer = oldContainer;
        this.component = component;
    }
}
export class ModelActiveSectionChangeEvent {
    section;
    constructor(section) {
        this.section = section;
    }
}
